<!--
 * @Description: 
 * @Author: 张宏军
 * @Date: 2024-11-03 12:23:00
 * @LastEditTime: 2024-11-14 19:29:32
 * @LastEditors: 张宏军
-->
<template>
    <div class="entrance">
        <video autoplay loop muted playsinline class="fullscreen-video">
            <source src="../assets/img/bgvideo.mp4" type="video/mp4">
            Your browser does not support the video tag.
        </video>
        <div class="tit1">内蒙古自治区地名历史文化大数据库</div>
        <div class="but">
            <el-button plain @click="$router.push('/map')">进入</el-button>
        </div>
        <div class="tit2">
            内蒙古师范大学蒙古学学院 <br>
            内蒙古自治区地名历史文化大数据库项目组
        </div>
    </div>
</template>
<script>
export default {
  name: "home",
  data() {

  }
}
</script>
<style lang="scss" scoped>
.fullscreen-video{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
    // z-index: -1;
}
.entrance{
    width: 100vw;
    height: 100vh;
//    background: url(../assets/img/homeBg.png) no-repeat center center fixed;
    // background-size: cover;
    background: url(../assets/img/homeBg.png) 0% 0% / cover no-repeat;
    position: static;
}
.tit1{
    position: fixed;
    width: 100%;
    text-align: center;
    top: 100px;
    font-size: 36px;
    font-weight: 600;
    color:#fff;
}
.but{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 288px;
    height: 40px;
    button{
        width: 288px;
        height: 40px;
        background-color: rgba(0,0,0,0);
        // background-color: #165DFF;
        border:1px solid #165DFF;
        border-radius: 16px;
        margin: 24px auto 0;
        text-align: center;
        line-height: 40px;
        color: #fff;
        font-size: 14px;
        letter-spacing: 0;
        cursor: pointer;
        // border: none;
        box-shadow: 1px 1px 10px #fff;
    }
}
.tit2{
    position: fixed;
    text-align: center;
    width: 100%;
    bottom: 50px;
    color:#fff;
    line-height: 1.5;
}
@media(max-width: 768px) {
    .tit1{
        font-size: 20px;
    }
}
</style>